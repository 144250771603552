export interface TaskState {
  id?: string;
  message?: string;
  error?: Error;
}

export enum TaskTypeEnum {
  TRADE_CREATE_ORDER = 'TRADE_CREATE_ORDER',
  TRADE_UPDATE_ORDER = 'TRADE_UPDATE_ORDER',
}
