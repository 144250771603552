import {
  CryptoPost,
  TradeOperationStatusEnum,
  TradePaymentMethod,
} from './crypto-post.model';

export interface TradeData extends CryptoPost {
  paymentMethod: TradePaymentMethod;
  walletId: string;
  walletNetwork: string;
  totalToPay: number;
  feedbackRate: number;
  feedbackComments: string;
  currentFlowStep: TradeFlowStep;
  initTime: number;
  paymentLimitTime: number;
  status: TradeOperationStatusEnum;
  transactionId: string;
}

export interface GoToStepData {
  step: TradeFlowStep;
  motalTitleHeader?: string | undefined;
  showBackArrow?: boolean | undefined;
}

export enum TradeFlowStep {
  INSERT_VALUES = 'INSERT_VALUES',
  AWAIT_CREATE_ORDER = 'AWAIT_CREATE_ORDER',
  AWAIT_MONEY_SEND_CONFIRM = 'AWAIT_MONEY_SEND_CONFIRM',
  AWAIT_MONEY_RECEIVED_CONFIRM = 'AWAIT_MONEY_RECEIVED_CONFIRM',
  CHAT = 'CHAT',
  PROVIDE_FEEDBACK = 'PROVIDE_FEEDBACK',
}

export interface OrderPostRequest {
  postId: string;
}

interface AdditionalOrderPostRequest {
  quantity: number;
  pricePaid: number;
}

export type CreateOrderPostRequest = OrderPostRequest & AdditionalOrderPostRequest;

export const TradeFlowStepPostStateEquivalent: Record<
  TradeOperationStatusEnum,
  TradeFlowStep
> = {
  [TradeOperationStatusEnum.OPENED]: TradeFlowStep.INSERT_VALUES,
  [TradeOperationStatusEnum.IN_PROGRESS]: TradeFlowStep.AWAIT_MONEY_SEND_CONFIRM,
  [TradeOperationStatusEnum.PAID_NOTIFIED]: TradeFlowStep.AWAIT_MONEY_RECEIVED_CONFIRM,
  [TradeOperationStatusEnum.COMPLETED]: TradeFlowStep.PROVIDE_FEEDBACK,
  [TradeOperationStatusEnum.CONFLICTED]: TradeFlowStep.INSERT_VALUES, // TODO: pending to define
  [TradeOperationStatusEnum.CLOSED]: TradeFlowStep.INSERT_VALUES, // TODO: pending to define
  [TradeOperationStatusEnum.DELETED]: TradeFlowStep.INSERT_VALUES, // TODO: pending to define
  [TradeOperationStatusEnum.EXPIRED]: TradeFlowStep.INSERT_VALUES, // TODO: pending to define
  [TradeOperationStatusEnum.CANCELED]: TradeFlowStep.INSERT_VALUES, // TODO: pending to define
  [TradeOperationStatusEnum.EXPIRED_RELEASE]: TradeFlowStep.INSERT_VALUES, // TODO: pending to define
  [TradeOperationStatusEnum.EXPIRED_PAYMENT]: TradeFlowStep.INSERT_VALUES, // TODO: pending to define
};
