import { createActionGroup, props } from '@ngrx/store';
import { PostTypeEnum } from '../../shared/pages/create-post/create-post.model';
import {
  CryptoPost,
  TradeOperationStatusEnum,
} from '../../shared/models/crypto-post.model';
import { CreateOrderPostRequest, TradeData } from '../../shared/models/trade.model';
import { TradePostState } from './trade-post.types';
import { UserPaymentMethod } from '../../shared/models/user-payment-method.model';

export const tradePostActions = createActionGroup({
  source: 'TradePost',
  events: {
    setTradePostState: props<{ tradePostState: TradePostState }>(),
    updateTradePostState: props<{
      postType?: PostTypeEnum;
      postData?: CryptoPost;
      tradeData?: Partial<TradeData>;
      userPaymentMethods?: UserPaymentMethod[];
      useInitialState?: boolean;
    }>(),
    setPostType: props<{ postType: PostTypeEnum }>(),
    setPostData: props<{ postData: CryptoPost }>(),
    setTradeData: props<{
      tradeData: Partial<TradeData>;
    }>(),
    setTradeStatus: props<{
      status: TradeOperationStatusEnum;
    }>(),
    createOrder: props<{
      postType: PostTypeEnum;
      data: CreateOrderPostRequest;
    }>(),
    notifyTradeStatusChange: props<{
      postType: PostTypeEnum;
      transactionId: string;
      triggerUsername: string;
      status: TradeOperationStatusEnum;
    }>(),
  },
});
